import React from "react";
import axios from "axios";

class Skills extends React.Component{

    render(){

        return(
            <section id="skills">
                        
                <h2 className="sectionHeading">
                    My Skills
                    <span className="fas fa-keyboard headingIcon"></span>
                </h2>
    
                <div className="flexContainer skillsFlex">
                        
                            <div className="skillRow">
                                <div className="flexContainer">
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">HTML5</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-html5 icon skill" aria-label="HTML5 Icon"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">CSS3</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-css3-alt icon skill" aria-label="CSS3 Icon"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">JavaScript</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-js-square icon skill" aria-label="JavaScript Icon"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">PHP</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-php icon skill" aria-label="PHP Icon"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">React</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fa-brands fa-react icon skill" aria-label="React Icon"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="skillRow skillRowCenter">
                                <div className="flexContainer">
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">Bootstrap</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="iconify icon skill" data-icon="akar-icons:bootstrap-fill"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">jQuery</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="iconify icon skill" data-icon="akar-icons:jquery-fill"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip toolTip">Sass</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="iconify icon skill" data-icon="akar-icons:sass-fill"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">Laravel</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-laravel icon skill" aria-label="Laravel Icon"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip">Node.js</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="fab fa-node icon skill" aria-label="Node JS Icon"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="skillRow">
                                <div className="flexContainer">
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip toolTipHigh">WordPress</div>
                                            <div className="triangleDown triangleHigh"></div>
                                        </div>
                                        <i className="iconify icon skill" data-icon="bxl:wordpress"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip toolTipHigh">mySQL</div>
                                            <div className="triangleDown triangleHigh"></div>
                                        </div>
                                        <i className="iconify icon skill" data-icon="fontisto:mysql"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip toolTipLong">Adobe Creative Cloud</div>
                                            <div className="triangleDown"></div>
                                        </div>
                                        <i className="iconify fa-database icon skill" data-icon="file-icons:adobe-creativecloud"></i>
                                    </div>
                                    <div className="SkillGroup">
                                        <div className="toolTipGroup">
                                            <div className="toolTip toolTipHigh">Nuke</div>
                                            <div className="triangleDown triangleHigh"></div>
                                        </div>
                                        <i className="iconify fa-database icon skill" data-icon="simple-icons:nuke"></i>
                                    </div>
                                </div>
                            </div>
                </div>
            </section>
        );
    }
}

export default Skills;