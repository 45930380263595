import React from "react";

class Contact extends React.Component {

    render(){
        return(

            <section id="contact">
    
                <h2 className="sectionHeading" id="contactHeading">
                    
                    Contact Information
                
                    <span className="iconify headingIcon" data-icon="bi:chat-left-text-fill"></span>
                
                </h2>

                <div className="flexContainer" id="contactData">

                    <p id="email">
                        <div className="flexContainer">
                            

                            <a className="contactLink" href="mailto:joshdevenyi@hotmail.com" target="_blank" rel="noreferrer">
                                <i class="fas fa-envelope" id="emailIcon"></i>
                            </a>

                            joshdevenyi@hotmail.com
                        </div>
                    </p>

                    <div className="linebreak"></div>
        
                    <a className="contactLink" href="https://www.linkedin.com/in/joshua-devenyi" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin icon"></i>
                    </a>
    
                    <a className="contactLink" href="https://github.com/JoshDevenyi" target="_blank" rel="noreferrer">
                        <i className="fab fa-github-square icon"></i>
                    </a>

                    <a className="contactLink" href="https://www.imdb.com/name/nm6271789/?ref_=fn_al_nm_1" target="_blank" rel="noreferrer">
                        <i id="imdbIcon" className="iconify icon" data-icon="fa:imdb"></i>
                    </a>
    
                </div>
    
            </section>
        );

    }
}

export default Contact;