import React from "react";

class Footer extends React.Component {

    render() {

        return(

            <footer id="footer">
                © Josh Devenyi, 2022
            </footer>

        );
    }
}

export default Footer;
