import React from "react";
import axios from "axios";

class Work extends React.Component {

    constructor(props) {

        super(props);
        this.state = {

            work: [],
            skills: []
        };
    }

    componentDidMount() {

        axios({
            url: "JSON/projects.json",
            method: "GET"
        })
        .then((res) => {
            this.setState({

                work: res.data

            });
        });
    }


    render() {

        const { work } =  this.state;

        return (

            <section id="work">
    
                <h2 className="sectionHeading">
                    
                    My Work
                
                    <span className="fa-solid fa-laptop-code headingIcon"></span>

                </h2>
    
                <div id="workExamples" className="flexContainerCol">

                    {work.map(w => (

                        <div className="workExample" key={w.id}>
        
                            <div className="workPanelFlex flexContainer">
                                
                                <a href={w.url} target="_blank" rel="noreferrer">
                                    <div className="imageBox">
            
                                        <img className="workImage imageGrow" src={w.image} alt={w.title + "Screen Cap"}/>
            
                                    </div>
                                </a>

                                <div className="workBox">
        
                                    <div className="workTextFlex flexContainerCol">
                                        
                                        <h3 className="workTitle">{w.title}</h3>
        
                                        <p className="workDescription">{w.description}</p>
                                        
                                        <div>

                                            <div className="flexContainer workIconFlex">

                                                <div className="workSkills">
                
                                                    <h4 className="workHeading">Skills Used</h4>
                                                    
                                                    {w.skills_used.map(function (s, i){
                                                        return <i className={s +" workIcon"} aria-label="HTML5 LOGO" key={i}></i>
                                                    })}

                                                </div>

                                                <div className="workGit">

                                                    <h4 className="workHeading">Repository</h4>
                                                    
                                                    <a className="contactLink" href={w.git} target="_blank" rel="noreferrer">
                                                        <i className="fab fa-github-square repoIcon"></i>
                                                    </a>
            
                                                </div>

                                            </div>

                                        </div>
        
                                    </div>
        
                                </div>
        
                            </div>
                            
                        </div>

                    ))}
    
                </div>
                
            </section>
    
        );

    }

}

export default Work;