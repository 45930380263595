import React from "react";
import Skills from './Skills';
import Education from './Education';

class Schooling extends React.Component {
    render() {
        return(

            <div id="schooling" className="flexContainer">
                <Skills/>
                <Education/>
            </div>

        );
    }
}
export default Schooling;