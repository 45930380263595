import React from "react";

function Nav() {

    return(

        <nav id="mainNavigation" aria-label="mainNavigation">
            <ul id="headerMenu" className="flexContainer">

                <li className="headerOption">

                    <a href="#work" className="headerLink">
                        Work
                    </a>

                </li>

                <li className="headerOption">

                    <a href="#skills" className="headerLink">
                        Skills
                    </a>

                </li>

                <li className="headerOption">

                    <a href="#education" className="headerLink">
                        Education
                    </a>

                </li>
                <li className="headerOption">

                    <a href="#contact" className="headerLink">
                        Contact 
                    </a>

                </li>
                <li className="headerOption">

                    <a href="files/JoshuaDevenyi_Resume.pdf" className="headerLink" download>
                        Resume 
                    </a>

                </li>
            </ul>
        </nav>
    );

}

export default Nav;