import React from "react";

class Profile extends React.Component {

    render(){

        return(

            <section id="profile" >

                <div id="profileContentBox" className="flexContainer">
    
                    <div id="textBox">
        
                        <div className="flexContainerCol">
                            
                            <h1 id="textHeading">Who is Josh?</h1>
                        
                            <p class="textContent">
                                I'm a 
                                <strong> Web Developer </strong>
                                based out of Toronto, Ontario, 
                                with a background in film production and visual effects. 
                                I’ve spent my career exploring creativity and am a strong believer 
                                that the best work is accomplished through collaboration. My experience has helped me develop a keen eye for detail, an imaginative spirit, and a eagerness to keep experimenting with new tools and technologies.
                            </p>

                            <div id="vfxGroup" className="flexContainer">
                                <p id="vfxText">Check out my visual effects demo reel!</p>
                                <a className="contactLink" href="https://vimeo.com/502778969" target="_blank" rel="noreferrer">
                                    <i class="iconify vfxIcon" data-icon="bxs:movie-play"></i>
                                </a>
                            
                            </div>
        
                        </div>
        
                    </div>

                    <div id="imageBox">
            
                            <div className="flexContainerCol imageFlex">
            
                                <img id="profileImage" src="images/NewJoshSquare.jpg" alt="Josh Devenyi" width="300"/>
            
                            </div>

                    </div>

                </div>

            </section>
        );

    }

}

export default Profile;